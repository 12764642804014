import React, { useEffect} from "react"

import Layout from "../components/wrappers/layout"
import SiteHeaderAlt from "../components/wrappers/site-header-alt"
import SEO from "../components/seo"
import { Link } from "gatsby"
import { domain } from "../utils"
import Header from '../components/header';
import TabbedItem from '../components/wrappers/tabbed-item';
import { graphql } from 'gatsby';
import { getSinglePage } from "../utils";
import { GlobalStateContext } from "../context/GlobalContextProvider"
import { useContext } from "react"


const SuccessStories = props => {
  const { footers, headers, successStoriesPage, general} = props.data.strapi;
  const copyright  = successStoriesPage?.copyright;
  const { generalBackgroundColorDarkMode, generalBackgroundColorLightMode,fontColorDarkMode ,fontColorLightMode } = general; 
  const language = props.pageContext.language
  const header = headers && headers.find((header)=>  header.language === language ); 
  const footer = footers && footers.find((footer)=>  footer.language === language ) || {}; 
  // getting  page first H1 to set as meta title and description if not set in strapi .
  const pageFirstHeading = "Testimonials" + " " + "Our success stories."

  const seoTitle = successStoriesPage && successStoriesPage.seo && successStoriesPage.seo.metaData.metaTitle || pageFirstHeading || "success stories ";
  const seoDescription = successStoriesPage &&  successStoriesPage.seo && successStoriesPage.seo.metaData.metaDescription || pageFirstHeading || "success stories ";
  const seoKeywords = successStoriesPage &&  successStoriesPage.seo && successStoriesPage.seo.metaData?.keywords;

  const allPages = props.data?.allSitePage?.nodes; 
  const webpages = successStoriesPage && successStoriesPage.webpages || {}
  const redirectTo = getSinglePage(webpages, allPages); 
  const state = useContext(GlobalStateContext);
  const darkMode = state.darkModePreference === 'moon'; 
  const sectionBackgroundColor = darkMode ? generalBackgroundColorDarkMode : generalBackgroundColorLightMode; 
  const websiteFontColor = darkMode ? fontColorDarkMode : fontColorLightMode; 

  if (copyright) {
    footer.copyright = copyright;
  }

  return (
    <Layout header={header} footer={footer} language={language} redirectTo={redirectTo}>
      <SEO
        websiteFontColor={websiteFontColor} 
        template="contact" 
        title={seoTitle} 
        description={seoDescription} 
        keywords={seoKeywords} 
        darkTheme={successStoriesPage?.darkTheme}  
        darkMode={darkMode}
        websiteBackgroundColor={sectionBackgroundColor}
      />
      <SiteHeaderAlt header={header} redirectTo={redirectTo}>
        <section className="section section-small-margin" style={{backgroundColor: sectionBackgroundColor}}>
          <Header heading="Our success stories." supheading="Testimonials" />
          <div className="stories">
            <article className="story">
              <div className="container">
                <div className="row">
                  <div className="offset-tw-1 col-tw-3 offset-lg-2 col-lg-2 story__author">
                    <img src="https://placehold.it/210" loading="lazy" />
                    <span><b>Dani Rayford</b></span>
                    <span>Business Systems Analyst</span>
                    <span>Franke Management Inc.</span>
                  </div>
                  <div className="col-tw-7 col-lg-6 story__body">
                    <h2>„Für uns ist Ventzke Media damit nicht mehr nur eine Agentur, sondern ein Partner.“</h2>
                    <p>“Franke hat für die Neugestaltung der globalen E-Commerce-Plattform Ventzke Media ausgewählt. Mit den Ergebnissen sind wir sehr zufrieden. Die Designer und Entwickler nahmen sich die Zeit, um unser Geschäft wirklich kennenzulernen. Das Team war sehr aufgeschlossen gegenüber unseren Anforderungen und haben diese mit großem Engagement erfüllt. Es wurde ein modernes und gradliniges Design geschaffen, ein Design, das zudem einfach für unsere Kunden in der Anwendung ist. Entsprechend haben wir ein sehr positives Feedback nach dem ersten Release unsere E-Commerce-Plattform erhalten. Für uns ist Ventzke Media damit nicht mehr nur eine Agentur, sondern ein Partner. Wir freuen uns darauf, unsere Geschäftsbeziehung mit Ventzke Media in der Zukunft zu stärken.”</p>
                  </div>
                </div>
              </div>
            </article>
            <article className="story">
              <div className="container">
                <div className="row">
                  <div className="offset-tw-1 col-tw-3 offset-lg-2 col-lg-2 story__author">
                    <img src="https://placehold.it/210" loading="lazy" />
                    <span><b>Dani Rayford</b></span>
                    <span>Business Systems Analyst</span>
                    <span>Franke Management Inc.</span>
                  </div>
                  <div className="col-tw-7 col-lg-6 story__body">
                    <h2>„Für uns ist Ventzke Media damit nicht mehr nur eine Agentur, sondern ein Partner.“</h2>
                    <p>“Franke hat für die Neugestaltung der globalen E-Commerce-Plattform Ventzke Media ausgewählt. Mit den Ergebnissen sind wir sehr zufrieden. Die Designer und Entwickler nahmen sich die Zeit, um unser Geschäft wirklich kennenzulernen. Das Team war sehr aufgeschlossen gegenüber unseren Anforderungen und haben diese mit großem Engagement erfüllt. Es wurde ein modernes und gradliniges Design geschaffen, ein Design, das zudem einfach für unsere Kunden in der Anwendung ist. Entsprechend haben wir ein sehr positives Feedback nach dem ersten Release unsere E-Commerce-Plattform erhalten. Für uns ist Ventzke Media damit nicht mehr nur eine Agentur, sondern ein Partner. Wir freuen uns darauf, unsere Geschäftsbeziehung mit Ventzke Media in der Zukunft zu stärken.”</p>
                  </div>
                </div>
              </div>
            </article>
            <article className="story">
              <div className="container">
                <div className="row">
                  <div className="offset-tw-1 col-tw-3 offset-lg-2 col-lg-2 story__author">
                    <img src="https://placehold.it/210" loading="lazy" />
                    <span><b>Dani Rayford</b></span>
                    <span>Business Systems Analyst</span>
                    <span>Franke Management Inc.</span>
                  </div>
                  <div className="col-tw-7 col-lg-6 story__body">
                    <h2>„Für uns ist Ventzke Media damit nicht mehr nur eine Agentur, sondern ein Partner.“</h2>
                    <p>“Franke hat für die Neugestaltung der globalen E-Commerce-Plattform Ventzke Media ausgewählt. Mit den Ergebnissen sind wir sehr zufrieden. Die Designer und Entwickler nahmen sich die Zeit, um unser Geschäft wirklich kennenzulernen. Das Team war sehr aufgeschlossen gegenüber unseren Anforderungen und haben diese mit großem Engagement erfüllt. Es wurde ein modernes und gradliniges Design geschaffen, ein Design, das zudem einfach für unsere Kunden in der Anwendung ist. Entsprechend haben wir ein sehr positives Feedback nach dem ersten Release unsere E-Commerce-Plattform erhalten. Für uns ist Ventzke Media damit nicht mehr nur eine Agentur, sondern ein Partner. Wir freuen uns darauf, unsere Geschäftsbeziehung mit Ventzke Media in der Zukunft zu stärken.”</p>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </section>
      </SiteHeaderAlt>
    </Layout>
  )
}

export const pageQuery = graphql`
  query SucessStories($language: String!) {
    allSitePage {
      nodes {
        path
      }
    }
    strapi {
      general {
        generalBackgroundColorDarkMode
        generalBackgroundColorLightMode
        fontColorDarkMode
        fontColorLightMode
      }
      successStoriesPage {
        copyright
        darkTheme
         webpages {
            about_us {
              language
            }
            contact {
              language
            }
            case_study {
              slug
              language
            }
            blogs_overview {
              language
            }
            cases_overview {
              language
            }
            careers_overview {
              language
            }	
            services_overview {
              language
            }
            blog {
              language
              slug
            }
            career {
              language
              slug
            }
            homepage {
              slug
              language
            }
            service_design {
              slug
              language
            }
            service_technical {
              slug
              language
            }
            service_casey_new {
              slug
              language
            }
            service_casey {
              slug
              language
            }
            plainpage {
              language
              slug
            }
          }
          seo {
           metaData {
            metaTitle
            metaDescription
            keywords
          }

        }
      }
      headers(where: {language: $language}) {
        ...SiteHeader
      }
      footers(where: { language: $language }) {
        ...SiteFooter
      }
    }
  }
`

export default SuccessStories
